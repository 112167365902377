var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.appraisal)?_c('div',[_c('div',{staticClass:"my-4"},[_c('v-chip',{staticClass:"mr-1",attrs:{"label":"","to":{
        name: _vm.$routes.ConsultantDetail,
        params: { id: _vm.appraisal.appraiser.id.toString() },
      }}},[_vm._v(" Appraiser: "),_c('b',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.appraisal.appraiser.name))])]),_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","to":{
        name: _vm.$routes.ConsultantDetail,
        params: { id: _vm.appraisal.appraisee.id.toString() },
      }}},[_vm._v(" Appraisee: "),_c('b',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.appraisal.appraisee.name))])]),_c('v-chip',{staticClass:"mx-1",attrs:{"label":""}},[_vm._v(" Appraisee's role: "),_c('b',{staticClass:"pl-1 text-capitalize"},[_vm._v(_vm._s(_vm.appraisal.appraiseeRole.name))])]),_c('v-chip',{staticClass:"mx-1 primary",attrs:{"label":""}},[_vm._v(" Score: "),_c('b',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.appraisal.surveyAssignment.score))])]),_c('v-divider',{staticClass:"mt-7"})],1),_c('subtitle',[_vm._v(" Completed on "+_vm._s(_vm.$moment(_vm.appraisal.updatedAt).format("Do MMM YYYY"))+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }