
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import SurveyForm from "@/components/surveys/SurveyForm.vue"
import PerformanceAppraisalDetail from "@/components/surveys/PerformanceAppraisalDetail.vue"
import { SurveyQuestion, SurveyAssignmentQuery, SurveyAssignmentFragmentFragment } from "@/gql"
import ScaleInput from "@/components/fields/ScaleInput.vue"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"

type Question = SurveyQuestion
type AssignmentType = SurveyAssignmentFragmentFragment & {
  questions?: Question[]
}

@Component({
  components: {
    AppPage,
    SurveyForm,
    ScaleInput,
    FeedBackMessage,
    PerformanceAppraisalDetail,
  },
})
export default class SurveyAssignmentDetail extends Vue {
  @Prop()
  readonly surveyAssignments!: AssignmentType[]
  readonly surveyAssignmentQuery = SurveyAssignmentQuery

  assignment: AssignmentType | null = null
  loading = false
  answers: { questionId: string; choice: any }[] = []

  @Watch("surveyAssignments", {
    immediate: true,
  })
  onSurveyAssignmentChange() {
    if (this.surveyAssignments && this.surveyAssignments.length) {
      // Set first completed survey assignment as default else use first assigment
      this.assignment =
        this.surveyAssignments.find((s) => s.state === "completed") || this.surveyAssignments[0]

      this.surveyAssignments.forEach((assignment) => {
        this.setQuestions(assignment)
      })
    } else {
      this.getAssignment()
    }
  }

  async getAssignment() {
    try {
      this.loading = true

      // Fetch survey assigment from server
      const result = await this.$apollo.query({
        query: this.surveyAssignmentQuery,
        variables: {
          id: parseInt(this.$route.params.id),
        },
      })

      if (result.data && result.data.surveyAssignment) {
        this.loading = false

        this.assignment = result.data.surveyAssignment
        this.assignment && this.setQuestions(this.assignment)
      }
    } catch (error) {
      this.addGraphQLError(error as Error)
    } finally {
      this.loading = false
    }
  }

  setQuestions(assignment: AssignmentType) {
    if (assignment && assignment.survey) {
      // Get survey questions
      const questions = assignment.survey.questions

      if (assignment.response) {
        questions &&
          questions.forEach((q) => {
            // Get answer for each question
            const answer = (assignment.response as any[]).find((a) => {
              // Find answer by question id
              return a.questionId === q.id
            })

            this.answers.push({
              questionId: q.id,
              choice: answer.choice,
            })
          })
      }
    }
  }

  getAnswers(question: Question) {
    // Array of choices
    let answers = this.answers.filter((a) => a.questionId === question.id).map((a) => a.choice)

    // Get aggregate answer based on question type
    switch (question.questionType) {
      case "scale": {
        if (answers.length === 0) return 0

        // Find average of choices
        const sum = answers.reduce((accu, curr) => accu + curr)
        return Math.round(sum / answers.length)
      }

      case "scored_single_choice":
        // Get option description as choice
        answers = answers.map((choice) => {
          const option = (question.options as any[]).find((o) => {
            return o.label === choice
          })
          return option.description
        })

        return answers

      default:
        return answers
    }
  }

  get completedSurveys() {
    return this.surveyAssignments.filter((s) => s.state === "completed")
  }
}
