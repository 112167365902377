
import { MissionPerformanceAppraisal, PerformanceAppraisalsQuery } from "@/gql"
import { Vue, Component, Prop } from "vue-property-decorator"

@Component
export default class PerformanceAppraisalDetail extends Vue {
  @Prop()
  readonly appraisalId!: string

  appraisal: MissionPerformanceAppraisal | null = null

  async created() {
    const result = await this.$apollo.query({
      query: PerformanceAppraisalsQuery,
      variables: {
        per: 1,
        filter: { idIn: this!.appraisalId },
      },
    })

    if (result.data?.missionPerformanceAppraisals) {
      this.appraisal = result.data.missionPerformanceAppraisals.data[0]
    }
  }
}
